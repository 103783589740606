@import "../../styles/variables";

.sceneListPage {
  display: flex;
  flex-direction: column;

  &.isEnableBulkEditorForm {
    width: calc(100% - 600px);
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .uploadButton {
      display: flex;
      svg {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }
    }
  }

  .sceneListHeader {
    display: flex;
    border-bottom: solid $black-1 1px;

    .column {
      display: flex;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      padding: 8px 12px;

      &.checkbox {
        width: 5%;
        min-width: 60px;
      }

      &.descriptor {
        width: 60%;
      }

      &.category {
        width: 15%;
      }

      &.createdAt {
        width: 10%;
      }

      &.tags {
        width: 15%;
      }

      &.isPublished {
        width: 10%;
        text-align: center;
      }
    }
  }

  .sceneListItemsWrap {
    display: flex;
    width: 100%;

    .sceneListItems {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
