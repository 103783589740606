@import './reset';
@import './fonts';
@import './variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  display: table;
}

body {
  width: 100%;
  display: table-cell;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'LL Circular', 'Apple SD Gothic Neo', sans-serif !important;
}

html,
body,
#root {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: 400;
  color: #fff;
  background: $black-0;
}

a,
button {
  cursor: pointer;
  align-items: center;
  display: flex;
  color: inherit;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
