.sceneAssetGroups {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  .assetGroup {
    margin-bottom: 12px;

    .inProgress {
      display: flex;

      .spinner {
        right: auto;
      }
      span {
        position: relative;

        span {
          padding-left: 40px;
        }
      }
    }

    .rejected {
      .title {
        margin-bottom: 2px;
      }
    }

    .approved {
      .title {
        margin-bottom: 2px;
      }
    }
  }
}
