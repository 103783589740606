@import "../../styles/variables";

.sceneListItem {
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: solid $black-1 1px;

  &:hover {
    background: $black-1;
  }

  .column {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 16px 12px;

    .thumbnail {
      width: 100px;
      min-width: 100px;
      margin-right: 16px;
      height: 80px;
      border-radius: 4px;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-color: #ffffff;
    }

    &.checkbox {
      width: 5%;
      min-width: 60px;
      z-index: 9999;
    }

    &.descriptor {
      width: 60%;
      flex-direction: row;
      justify-content: flex-start;

      .inner {
        display: flex;
        flex-direction: column;

        .name, .brand, .price, .description {
          margin-bottom: 4px;
        }

        .brand, .price {
          opacity: .6;
        }
      }
    }

    &.category {
      width: 15%;

      .categoryStep {
        display: flex;
        align-items: center;
      }
    }

    &.createdAt {
      width: 10%;
    }

    &.isPublished {
      width: 10%;
      text-align: center;
    }
  }
}
