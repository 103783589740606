@import '../../styles/variables';

.textField {
  border: 0;
  margin: 0;
  outline: none;
  padding: 0;
  vertical-align: baseline;
  min-width: 300px;
  margin-bottom: 16px;

  input {
    color: #fff;
    appearance: none;
    background: $black-1;
    border: none;
    border-radius: 6px;
    display: block;
    font-size: 14px;
    line-height: 140%;
    outline: none;
    padding: 14px 16px;
    resize: none;
    transition: border-color 0.15s ease-out, background 0.15s ease-out;
    width: 100%;
    font-family: 'LL Circular', 'Apple SD Gothic Neo', sans-serif !important;

    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0 3px rgba($primary, 0.5);
    }

    &:focus,
    &:hover {
      background: mix($black-1, #000, 90%);
    }
  }

  .label {
    margin-bottom: 8px;
  }

  .validationText {
    padding-top: 8px;
    padding-bottom: 12px;
    color: #ea6e5e;
  }

  &.isError {
    input {
      border-color: #ea6e5e;
    }
  }

  &.isReadonly {
    input {
      color: $black-3;
    }
  }

  &.small {
    input {
      padding: 10px 12px;
    }
  }
}
