.dimmer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .7);
  z-index: 9999;
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 9999;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .actions {
      margin-bottom: 12px;

      .closeButton {
        font-size: 32px;
        background: none;
        border: none;
      }
    }
  }
}
