@import '../../styles/variables';

.dropzoneItem {
  margin-bottom: 20px;
  width: 165px;
  overflow: hidden;
  margin-right: 20px;

  .thumbnail {
    width: 100%;
    height: 165px;
    margin-bottom: 6px;
    border-radius: 4px;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    position: relative;
    background-color: $black-0;

    &.loaded {
      background-image: url(../../static/images/checkerboard.jpg);
    }
  }

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: 6px;
  }
}
