@import "../../styles/variables";

.categoryUpsertModal {
  background: $black-0;
  min-width: 800px;
  border-radius: 8px;
  padding: 24px;

  .header {
    margin-bottom: 32px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
