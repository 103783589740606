@import "../../styles/variables";

.checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .shape {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    background: $black-0;
    border: solid $black-1 2px;
    justify-content: center;
    align-items: center;
    transition: border-color 0.15s ease-out, background 0.15s ease-out;

    svg {
      transition: opacity 0.15s ease-out;
      opacity: 0;
    }

    &.isChecked {
      background: $primary;
      font-size: 18px;

      svg {
        opacity: 1;
      }
    }

    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0 3px rgba($primary, 0.5);
    }
  }
}
