@import '../../styles/variables';

.loginForm {
  display: flex;
  flex-direction: column;
  min-width: 400px;

  .title {
    text-align: center;
    padding-top: 12px;
    margin-bottom: 24px;
  }

  .form {
    display: flex;
    flex-direction: column;

    .errorMessage {
      color: #ea6e5e;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 24px;

      svg {
        margin-right: 4px;
      }
    }

    .actions {
      padding-top: 16px;
      display: flex;
      justify-content: flex-end;

      .loginButton {
        width: 100%;
      }
    }
  }
}
