@import '../../styles/variables';

.sceneUpdateForm {
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;

    & > button {
      margin-left: 10px;
    }
  }
  .subactions {
    display: flex;
    margin-bottom: 16px;
  }

  .viewer {
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  .slider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 300px;
  }

  .preview {
    margin-bottom: 16px;

    .qrcode {
      margin-bottom: 6px;

      .title {
        margin-bottom: 6px;
      }
      width: 148px;
      padding: 10px;
      background: $black-1;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
    }
  }

  .thumbnail {
    margin-bottom: 16px;
    img {
      margin-bottom: 8px;
      background-color: #fff;
      border-radius: 6px;
    }
  }

  .monacoEditor {
    margin-bottom: 16px;
    border-radius: 6px;
    overflow: hidden;
  }
}

.modal {
  background: $black-0;
  min-width: 800px;
  border-radius: 8px;

  &.confirmDeletePopup {
    padding: 24px;
    min-width: 600px;

    .actions {
      display: flex;
      justify-content: flex-end;
      padding-top: 12px;
    }
  }
}
