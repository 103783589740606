$max-width: 1200px;

$primary: #396eff;
$danger: #e74c3c;
$light-gray: #a0a0a2;

$black-0: #18191f;
$black-1: #24252b;
$black-2: #56575d;
$black-3: #7f8186;
$black-4: #a4a5a7;
