@import '../../styles/variables';

.categoryItemForm {
  position: absolute;
  width: 100%;

  .inner {
    display: flex;
    background: $black-1;
    border-bottom: solid 1px $black-0;
    align-items: center;
    min-height: 45px;
    justify-content: space-between;
    .block {
      display: flex;

      .dragable {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        width: 30px;
        cursor: grabbing;
        color: rgba(255, 255, 255, .3);
      }

      .count {
        margin-left: 4px;
      }

      &.actions {
        & > button {
          margin-right: 4px;
        }
      }
    }
  }
  .subCategories {
    padding-left: 40px;
  }
}


.modal {
  background: $black-0;
  min-width: 800px;
  border-radius: 8px;

  &.confirmDeletePopup {
    padding: 24px;
    min-width: 600px;

    .title {
      margin-bottom: 32px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      padding-top: 12px;
    }
  }
}
