@import '../../styles/variables';

.header {
  min-height: 65px;
  display: flex;
  justify-content: center;
  background: $black-1;

  .inner {
    display: flex;
    width: 100%;
    padding: 0 12px;
    justify-content: space-between;

    .actions {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}
