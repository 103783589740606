@import '../../styles/variables';

.asideItem {
  display: flex;
  position: relative;
  width: 100%;
  height: 45px;
  transition: background 0.15s ease-in-out;

  .anchor {
    padding: 16px;
    display: flex;
    width: 100%;
    vertical-align: middle;
    align-items: center;
    font-size: 14px;
    color: $black-4;
    transition: color 0.15s ease-in-out;

    svg {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
      font-size: 18px;
    }
  }

  &.isActivated {
    background: $primary;

    .anchor {
      color: #ffffff;
    }
  }
}
