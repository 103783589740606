.userInformationUpdateForm {
  .profile {
    width: 100px;
    margin-bottom: 6px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;

    & > button {
      margin-left: 10px;
    }
  }
}
