@import '../../styles/variables';

.aside {
  width: 100%;
  height: 100vh;
  background: $black-1;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65px;

    a {
      text-indent: -9999px;
      overflow: hidden;
      display: flex;
      width: 100%;
      height: 100%;
      background: url(../../static/images/place_ar_temp_logo.png) no-repeat
        center;
      background-size: 65%;
    }
  }
}
