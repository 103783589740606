@import '../../styles/variables';

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid $black-1;
  border-top: 3px solid $primary;
  border-radius: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: spin 0.6s infinite linear;

  &.white {
    border: 3px solid rgba(#fff, 0);
    border-top: 3px solid #fff;
  }

  &.small {
    width: 25px;
    height: 25px;
  }

  &.medium {
    width: 40px;
    height: 40px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
