.dashBoard {
  margin-top: 30px;
  height: 800px;
  max-height: 800px;
  width: 100%;

  canvas {
    width: 100%;
    height: 100%;
    //border: 1px solid red;
  }
}