@import "../../styles/variables";

.switch {
  margin-bottom: 16px;

  .label {
    margin-bottom: 8px;
  }
  .button {
    height: 30px;
    width: 54px;
    border: none;
    background: $black-1;
    appearance: none;
    border-radius: 100px;
    position: relative;
    transition: background 0.15s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      height: 22px;
      width: 22px;
      background: $black-2;
      border-radius: 50%;
      left: 4px;
      top: 4px;
      transition: background 0.15s ease-in-out, transform 0.15s ease-in-out;
    }
  }

  &.isActivated {
    .button {
      background: $primary;

      &::after {
        background: #fff;
        transform: translate(24px);
      }
    }
  }
}
