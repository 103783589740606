@font-face {
  font-family: LL Circular;
  src: url(../static/fonts/Circular-400.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: LL Circular;
  src: url(../static/fonts/Circular-450.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: LL Circular;
  src: url(../static/fonts/Circular-500.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: LL Circular;
  src: url(../static/fonts/Circular-700.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: 400;
  src: url(../static/fonts/AppleSDGothicNeo-40.woff2) format('woff2');
}

@font-face {
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: 500;
  src: url(../static/fonts/AppleSDGothicNeo-50.woff2) format('woff2');
}

@font-face {
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: 600;
  src: url(../static/fonts/AppleSDGothicNeo-60.woff2) format('woff2');
}

@font-face {
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: 700;
  src: url(../static/fonts/AppleSDGothicNeo-70.woff2) format('woff2');
}
