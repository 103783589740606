@import '../../styles/variables';

.dropdownOption {
  padding: 12px 14px;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  position: relative;

  &:hover {
    background: mix($black-1, #000, 90%);
  }

  &.isSelected {
    background: mix($black-1, #000, 80%);
  }
}
