@import '../../styles/variables';

.dropdownGroup {
  width: 100%;

  .name {
    background: mix($black-0, #fff, 97.5%);
    padding: 6px 14px;
  }
}
