@import "../../styles/variables";

.formColumn {
  display: flex;
  border-bottom: solid $black-1 1px;
  padding-top: 16px;
  &:first-child {
    border-top: solid $black-1 1px;
  }

  &:last-child {
    border-bottom: none;
  }

  .header {
    display: flex;
    flex-direction: column;
    min-width: 175px;
    padding-bottom: 16px;

    .additional {
      margin-top: 6px;
    }
  }

  .children {
    width: 100%;
  }
}
