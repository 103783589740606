.typography {
  line-height: 1.4;

  &.h1 {
    font-size: 32px;
    font-weight: 600;
  }

  &.h2 {
    font-size: 24px;
    font-weight: 600;
  }

  &.h3 {
    font-size: 16px;
    font-weight: 600;
  }

  &.p {
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  &.normal {

  }

  &.info {
    color: #717277;
  }

  &.error {
    color: #ea6e5e;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}
