.app {
  display: flex;
  flex-direction: column;
  width: 100%;

  .main {
    display: flex;

    .aside {
      min-width: 250px;
      display: flex;
      flex-direction: column;
      position: fixed;
      z-index: 99;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 250px;
      position: relative;

      .header {
        position: fixed;
        width: calc(100% - 250px);
        z-index: 99;
      }

      .body {
        padding: 40px;
        padding-top: 105px;
      }
    }
  }
}
