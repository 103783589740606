@import '../../styles/variables';

.dropzone {
  margin-bottom: 16px;
  .area {
    background: $black-1;
    border: solid $black-1 1px;
    min-height: 300px;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-top: 20px;
    transition: border-color 0.15s ease-in-out;

    .guide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      svg {
        font-size: 48px;
        margin-bottom: 12px;
      }
    }
  }

  &.isActivated {
    .area {
      border-color: $primary;
    }
  }
}
