@import '../../styles/variables';

.dropdown {
  margin-bottom: 16px;
  .selectedItem {
    color: #fff;
    appearance: none;
    background: $black-1;
    border: none;
    border-radius: 6px;
    display: block;
    font-size: 14px;
    line-height: 140%;
    outline: none;
    padding: 14px 16px;
    resize: none;
    transition: border-color 0.15s ease-out, background 0.15s ease-out;
    width: 100%;
    cursor: pointer;
    position: relative;
    user-select: none;

    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0 3px rgba($primary, 0.5);
    }

    &:focus,
    &:hover {
      background: mix($black-1, #000, 90%);
    }

    .icon {
      position: absolute;
      font-size: 24px;
      width: 40px;
      color: $black-0;
      right: 0;
      top: 0;
      height: 100%;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.groups {
  position: absolute;
  background: $black-1;
  box-shadow: 0 8px 18px 0 $black-0;
  border-radius: 4px;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 9999;
}
