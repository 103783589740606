@import "../../styles/variables";

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-bottom: 16px;

  .header {
    margin-right: 24px;
    min-width: 50px;
    display: flex;

    .label {
      margin-right: 8px;
    }
  }

  .inner {
    display: flex;
    width: 100%;
    height: 25px;
    align-items: center;
    justify-content: center;
    position: relative;

    .progressWrap {
      width: 100%;
      height: 4px;
      display: flex;
      border-radius: 50px;
      position: relative;

      .progressBackground {
        display: flex;
        height: 100%;
        width: 100%;
        background: $black-1;
        border-radius: 50px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .progress {
        display: flex;
        will-change: width;
        height: 100%;
        background: $primary;
        border-radius: 50px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .cursor {
      position: absolute;
      left: -12.5px;
      top: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      appearance: none;
      border: none;
      background: $primary;
    }
  }
}
