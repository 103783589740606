@import "../../styles/variables";

.bulkSceneEditorForm {
  width: 600px;
  position: fixed;
  right: 0;
  top: 0;
  background: $black-0;
  box-shadow: 0 12px 24px 1px #141414;
  z-index: 9999;
  height: 100%;
  overflow: auto;

  .inner {
    padding: 24px;

    .monacoEditor {
      margin-bottom: 16px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      padding-top: 12px;

      & > button {
        margin-right: 10px;
      }
    }
  }
}

.modal {
  background: $black-0;
  min-width: 800px;
  border-radius: 8px;

  &.confirmDeletePopup {
    padding: 24px;
    min-width: 600px;

    .title {
      margin-bottom: 32px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      padding-top: 12px;
    }
  }
}
