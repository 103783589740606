@import '../../styles/variables';

.button {
  position: relative;
  background: $primary;
  appearance: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
  overflow: hidden;

  &.primary {
    background: $primary;
  }

  &.danger {
    background: $danger;
  }

  &:hover {
    &.primary {
      background: mix($primary, #000, 90%);
    }

    &.danger {
      background: mix($danger, #000, 90%);
    }
  }

  &.medium {
    font-size: 16px;
    padding: 12px 24px;
  }

  &.large {
    font-size: 18px;
    padding: 16px 32px;
  }

  &.rounded {
    border-radius: 50px;
  }

  &.isLoading {
    background: $black-1;
    color: rgba(#fff, .0);
  }

  &.transparent {
    background: none;
    font-size: 16px;
    padding: 10px;

    &:hover {
      background: none;
    }
  }
}
