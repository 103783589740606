.dashBoardSearchForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 0 10px;

  .header {
    margin-bottom: 12px;
  }

  .form {
    max-width: 500px;
  }
}
