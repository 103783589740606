@import "../../styles/variables";

.svg {
  height: 100%;
  width: 100%;
  min-width: 800px;


  .rect {
    stroke-opacity: 0.2;
    fill: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .info {
    z-index: 2;
    border: 1px solid white;
    color: white;
  }

  .dot {
    fill: $primary;
    stroke: $primary;
    display: none;
  }

  .path {
    stroke: $primary;
    fill: transparent;
    stroke-width: 3;
    stroke-linecap: round;
  }

  .verticalIndicator {
    fill: white;
    stroke: white;
    stroke-opacity: 0.15;
    display: none;
    stroke-dasharray: 2;
  }

  .horizonIndicator {
    stroke: white;
    stroke-opacity: 0.05;
  }

  .horizonText {
    fill: white;
    font-size: 10px
  }

  .count {
    display: none;
    fill: white;
    font-size: 13px;
  }

  .date {
    fill: white;
    font-size: 12px;

    &.hide {
      opacity: 0;
    }

  }
}

